<template>
  <div>
    <div id="selfie-cam">
      <main id="liveness">
        <div class="w-full text-4xl absolute text-black font-bold text-center z-50 top-[3%]">Selfie</div>
        <div v-if="!loading" class="overlay"></div>
        <canvas id="camera--sensor" ref="cameraSensorId"></canvas>
        <video id="camera--view" autoplay playsinline ref="cameraViewId"></video>
        <img src="//:0" alt="" id="camera--output" ref="cameraOutputId">
        <button class="z-50" type="button" @click="takeSelfie" id="camera--trigger" :disabled="loading">
          <span v-if="!loading">Take a picture</span>
          <span v-else>Loading...</span>
        </button>
        <div v-if="loading" class="full-screen-overlay">
          <div class="loader z-50"></div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import store from '../store';

export default {
  name: "SelfieCamera",
  props: {
    errorCount:{
      type: Number,
      required: true
    },
  },
  data() {
    return {
      key: null,
      loading: false,
      constraints: {
        video: {
          facingMode: "user",
          width: { min: 520, ideal: 1280, max: 1280 },
          height: { min: 520, ideal: 720, max: 1280 }
        },
        audio: false
      },
      trackId: null,
      base64SelfieData: "",
      mediaStream: null,
    };
  },
  mounted() {
    console.log('[EKYC Selfie] Error count:', this.errorCount);
    const journeyId = sessionStorage.getItem('journeyId');
    console.log('[EKYC Selfie] Mounted with journey ID:', journeyId);
    if (!journeyId) {
      console.warn('[EKYC Selfie] No journey ID found, redirecting to start');
      this.$router.push({ name: 'start-ekyc' });
    }
    this.startCamera();
  },
  beforeDestroy() {
    this.closeCamera();
  },
  methods: {
    closeCamera() {
      console.log('[EKYC Selfie] Closing camera');
      let tracks = this.mediaStream.getTracks();
      tracks.forEach(track => track.stop() )
    },
    async centralizedOkayFace(journeyId, selfieImage) {
      console.log('[EKYC Selfie] Calling centralize-okay-face API');
      try {
        const response = await this.$http.post('/api/centralize-okay-face', { journeyId, selfieImage });
        console.log('[EKYC Selfie] API Response:', response.data);
        return response;
      } catch (error) {
        console.error('[EKYC Selfie] API Error:', error);
        if (error.response) {
          console.error('[EKYC Selfie] Response data:', error.response.data);
          console.error('[EKYC Selfie] Response status:', error.response.status);
          if (error.response.status === 500) {
            console.log('[EKYC Selfie] Server error, retrying capture');
            this.takeSelfie(); // Retry taking the selfie
          }
        }
        throw error;
      }
    },
    startCamera() {
      console.log('[EKYC Selfie] Starting camera');
      navigator.mediaDevices
          .getUserMedia(this.constraints)
          .then(stream => {
            console.log('[EKYC Selfie] Camera started successfully');
            this.mediaStream = stream;
            this.trackId = stream.getTracks()[0];
            this.$refs.cameraViewId.srcObject = stream;
          })
          .catch(error => {
            console.error("[EKYC Selfie] Camera error:", error);
          });
    },
    async takeSelfie() {
      console.log('[EKYC Selfie] Taking selfie');
      
      // Mixpanel tracking
      if (process.env.VUE_APP_ENV === 'production'){
        try {
          this.$mixpanel.track('E-Kyc Selfie Captured', {
            userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
            current_form: 'E-Kyc Selfie Capture Camera',
            selected_document_type: sessionStorage.getItem('documentType'),
            journey_id: sessionStorage.getItem('journeyId')
          });
        } catch (error) {
          console.log('[EKYC Selfie] Mixpanel error:', error);
        }
      }

      this.loading = true;
      console.log('[EKYC Selfie] Processing image capture');

      const cameraViewId = this.$refs.cameraViewId;
      const cameraSensorId = this.$refs.cameraSensorId;
      const cameraOutputId = this.$refs.cameraOutputId;

      // Set canvas size to match the video stream
      cameraSensorId.width = cameraViewId.videoWidth;
      cameraSensorId.height = cameraViewId.videoHeight;
      console.log('[EKYC Selfie] Image dimensions:', { width: cameraSensorId.width, height: cameraSensorId.height });

      // Draw the current frame from the video onto the canvas
      cameraSensorId.getContext("2d").drawImage(cameraViewId, 0, 0);

      // Get the data URL of the image
      const imageDataUrl = cameraSensorId.toDataURL("image/jpeg", 1.0);
      
      console.log('[EKYC Selfie] Image captured successfully');
      
      cameraOutputId.src = imageDataUrl;
      cameraOutputId.classList.add("taken");

      // Extract the base64 string from the data URL
      this.base64SelfieData = imageDataUrl.replace(/^data:image\/[^;]+;base64,/, '');

      const key = this.$route.query.key; // Get the key from the current URL
      // Get the journeyId from sessionStorage
      const journeyId = sessionStorage.getItem('journeyId');
      console.log('[EKYC Selfie] Processing with journey ID:', journeyId);

      this.closeCamera();

      try {
        let okayFaceResponse = await this.centralizedOkayFace(journeyId, this.base64SelfieData);
        if (okayFaceResponse.data && okayFaceResponse.data.status === 'success') {
          console.log('[EKYC Selfie] API call successful');
          this.$toast.success('Images Uploaded Successfully');
          if (journeyId){
            console.log('[EKYC Selfie] Redirecting to summary with journeyId:', journeyId);
            this.$router.push({ name: 'document-summary', query: { JID : journeyId, key } });

            try {
              await this.$http.post('/api/update-okay-face-status', {
                journeyId,
                status: 'success'
              });
              console.log('Status updated to success');
            } catch (statusError) {
              console.log('Error updating status:', statusError);
            }
          } else {
            console.error('[EKYC Selfie] Journey ID missing after successful upload');
          }
        } else {
          console.warn('[EKYC Selfie] API returned non-success status');
          // Mixpanel tracking
          if (process.env.VUE_APP_ENV === 'production'){
            try {
              this.$mixpanel.track('E-Kyc selfie captured unsuccessfully due to inappropriate image format', {
                userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
                current_form: 'E-Kyc Selfie Capture Camera',
                selected_document_type: sessionStorage.getItem('documentType'),
                journey_id: sessionStorage.getItem('journeyId')
              });
            } catch (error) {
              console.log('[EKYC Selfie] Mixpanel trackingerror:', error);
            }
          }

          try {
            await this.$http.post('/api/update-okay-face-status', {
              journeyId,
              status: 'failed'
            });
            console.log('Status updated to failed');
          } catch (statusError) {
            console.log('Error updating status:', statusError);
          }

          try {
            await this.$http.post('/api/update-okay-face-status', {
              journeyId,
              status: 'failed'
            });
            console.log('Status updated to failed');
          } catch (statusError) {
            console.log('Error updating status:', statusError);
          }

          this.$toast.error('Inappropriate image format, please retake');
          this.handleError();
        }
      } catch (error) {
        console.error('[EKYC Selfie] Error processing selfie:', error);
        if (error.response) {
          console.error('[EKYC Selfie] Response data:', error.response.data);
          console.error('[EKYC Selfie] Response status:', error.response.status);
        }
      } finally {
        this.loading = false;
      }
    },
    handleError() {
      console.log('[EKYC Selfie] Handling error, current count:', this.errorCount);

      if (this.errorCount >= 1) {
        console.log('[EKYC Selfie] Max attempts reached, redirecting to summary');
        this.$toast.error('Exceeded maximum attempt.');
        const journeyId = sessionStorage.getItem('journeyId');
        if (journeyId){
          console.log('[EKYC Selfie] Redirecting to summary with journeyId:', journeyId);
          this.$router.push({ name: 'document-summary', query: { JID : journeyId } });
        } else {
          console.error('[EKYC Selfie] Journey ID missing during error handling');
        }
      } else {
        console.log('[EKYC Selfie] Retrying capture');
        this.startCamera();
        this.$emit('failed', { type:"selfie" });
      }
    }
  }
};
</script>
