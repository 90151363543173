<template>
  <div id="id-capture-cam">
    <main id="ocr">
      <div class="w-full text-4xl absolute text-black font-bold text-center z-50 top-[3%]">Front ID</div>
      <div v-if="!loading" class="frame"></div>
      <canvas id="camera--sensor" ref="cameraSensorId"></canvas>
      <video id="camera--view" autoplay playsinline ref="cameraViewId"></video>
      <img :src="imageSrc" alt="" id="camera--output" ref="cameraOutputId" :class="{hidden: !imageCaptured}">
      <button class="z-50" type="button" @click="takeIdPicture" id="camera--trigger" :disabled="loading">
        <span v-if="!loading">Take a picture</span>
        <span v-else>Loading...</span>
      </button>
      <div v-if="loading" class="full-screen-overlay">
        <div class="loader z-50"></div>
      </div>
      <input type="hidden" name="base64FaceId" ref="base64FaceId">
    </main>
  </div>
</template>

<script>
require('@/assets/ekyc-camera.css');
import store from '../store';

export default {
  props: {
    errorCount:{
      type: Number,
      required: true
    },
  },
  name: "IDCaptureCamera",
  data() {
    return {
      loading: false,
      constraints: {
        video: {
          facingMode: "environment",
          width: { min: 520, ideal: 1280, max: 1280 },
          height: { min: 520, ideal: 720, max: 1280 }
        },
        audio: false
      },
      trackId: null,
      base64IdData: "",
      imageSrc: '',
      imageCaptured: false,
      mediaStream: null,
    };
  },
  mounted() {
    const journeyId = sessionStorage.getItem('journeyId');
    console.log('[EKYC Front ID] Mounted with journey ID:', journeyId);
    if (!journeyId) {
      console.warn('[EKYC Front ID] No journey ID found, redirecting to start');
      this.$router.push({ name: 'start-ekyc' });
    }
    this.startCamera();
  },
  beforeDestroy() {
    this.closeCamera();
  },
  methods: {
    resetCameraState() {
        // Reset camera
        if (this.mediaStream) {
            this.closeCamera();
        }
        // Reset image states
        this.imageCaptured = false;
        this.imageSrc = '';
        this.base64IdData = '';
    },
    closeCamera() {
      let tracks = this.mediaStream.getTracks();
      tracks.forEach(track => track.stop() )
    },
    startCamera() {
      console.log('[EKYC Front ID] Starting camera');
      navigator.mediaDevices
          .getUserMedia(this.constraints)
          .then(stream => {
            console.log('[EKYC Front ID] Camera started successfully');
            this.mediaStream = stream;
            this.trackId = stream.getTracks()[0];
            this.$refs.cameraViewId.srcObject = stream;
          })
          .catch(error => {
            console.error("[EKYC Front ID] Camera error:", error);
          });
    },
    async takeIdPicture() {
      console.log('[EKYC Front ID] Taking picture');
      
      // Mixpanel tracking
      if (process.env.VUE_APP_ENV === 'production'){
        try {
          this.$mixpanel.track('E-Kyc ID Front Image Captured', {
            userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
            current_form: 'E-Kyc ID Front Capture Camera',
            selected_document_type: sessionStorage.getItem('documentType'),
            journey_id: sessionStorage.getItem('journeyId')
          });
        } catch (error) {
          console.log('[EKYC Front ID] Mixpanel error:', error);
        }
      }

      this.loading = true;
      console.log('[EKYC Front ID] Processing image capture');

      const cameraViewId = this.$refs.cameraViewId;
      const cameraSensorId = this.$refs.cameraSensorId;
      const cameraOutputId = this.$refs.cameraOutputId;

      cameraSensorId.width = cameraViewId.videoWidth;
      cameraSensorId.height = cameraViewId.videoHeight;
      console.log('[EKYC Front ID] Image dimensions:', { width: cameraSensorId.width, height: cameraSensorId.height });

      cameraSensorId.getContext("2d").drawImage(cameraViewId, 0, 0);
      const imageDataUrl = cameraSensorId.toDataURL("image/jpeg", 1.0);
      
      console.log('[EKYC Front ID] Image captured successfully');
      
      cameraOutputId.src = imageDataUrl;
      cameraOutputId.classList.add("taken");
      this.imageCaptured = true;
      this.base64IdData = imageDataUrl.replace(/^data:image\/[^;]+;base64,/, '');
      
      console.log('[EKYC Front ID] Storing image in session storage');
      sessionStorage.setItem('docFront', this.base64IdData);

      const documentType = sessionStorage.getItem('documentType');
      console.log('[EKYC Front ID] Document type:', documentType);
      
      this.closeCamera();

      if (documentType === "passport") {
        const journeyId = sessionStorage.getItem('journeyId');
        console.log('[EKYC Front ID] Processing passport with journey ID:', journeyId);

        try {
          console.log('[EKYC Front ID] Calling centralize-okay-id API');
          let response = await this.$http.post('/api/centralize-okay-id', {
            journeyId,
            docFront: this.base64IdData,
            docBack: null // Since it's a passport, docBack is not required
          });
          
          console.log('[EKYC Front ID] API Response:', response.data);
          
          if (response.data && response.data.status === 'success') {
            console.log('[EKYC Front ID] API call successful, proceeding to next step');
            this.$toast.success('Front ID Captured Successfully');
            this.$emit('next-step', true);

            try {
              await this.$http.post('/api/update-okay-id-status', {
                journeyId,
                status: 'success'
              });
              console.log('Status updated to success');
            } catch (statusError) {
              console.log('Error updating status:', statusError);
            }
          } else {
            console.warn('[EKYC Front ID] API returned non-success status');
            // Mixpanel tracking
            if (process.env.VUE_APP_ENV === 'production'){
              try {
                this.$mixpanel.track('E-Kyc ID Front Image captured unsuccessfully due to inappropriate image format', {
                  userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
                  current_form: 'E-Kyc ID Front Capture Camera',
                  selected_document_type: sessionStorage.getItem('documentType'),
                  journey_id: sessionStorage.getItem('journeyId')
                });
              } catch (error) {
                console.log('[EKYC Front ID] Mixpanel error:', error);
              }
            }

            try {
              await this.$http.post('/api/update-okay-id-status', {
                journeyId,
                status: 'failed'
              });
              console.log('Status updated to failed');
            } catch (statusError) {
              console.log('Error updating status:', statusError);
            }

            this.$toast.error('Inappropriate image format, please retake');
            this.handleError();
          }
        } catch (error) {
          // Mixpanel tracking
          if (process.env.VUE_APP_ENV === 'production'){
            try {
              this.$mixpanel.track('E-Kyc ID Front Image captured unsuccessfully due to error centralizing Okay ID', {
                userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
                current_form: 'E-Kyc ID Front Capture Camera',
                selected_document_type: sessionStorage.getItem('documentType'),
                journey_id: sessionStorage.getItem('journeyId')
              });
            } catch (error) {
              console.log('Mixpanel tracking error: ', error);
            }
          }

          console.error('Error centralizing Okay ID:', error);
          this.handleError();
        } finally {
          this.loading = false;
        }
      } else {
        console.log('[EKYC Front ID] MyKad captured, proceeding to next step');
        this.$toast.success('Front ID Captured Successfully');
        this.$emit('next-step');
        this.loading = false;
      }
    },
    handleError() {
      console.log('[EKYC Front ID] Handling error, current count:', this.errorCount);
      
      if (this.errorCount >= 1) {
        console.log('[EKYC Front ID] Max attempts reached, redirecting to summary');
        this.closeCamera();
        this.$toast.error('Exceeded maximum attempt.');
        const journeyId = sessionStorage.getItem('journeyId');
        if (journeyId){
          this.$router.push({ name: 'document-summary', query: { JID : journeyId } });
        } else {
          console.error('[EKYC Front ID] Journey ID missing during error handling');
        }
      } else {
        console.log('[EKYC Front ID] Retrying capture');
        this.resetCameraState();
        this.startCamera();
        this.$emit('failed', { type:"doc" });
      }
    }
  }
};
</script>
