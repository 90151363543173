<template>
  <div class="p-6 bg-gray-100 min-h-screen content-center flex flex-col items-center justify-center">
    <h1 class="text-2xl font-semibold mb-4">Uploaded Documents</h1>
    <div class="text-center flex flex-col items-center justify-center lg:w-1/4">
      <div class="bg-white shadow-md rounded-lg overflow-hidden w-full">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
          <tr>
            <th class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
              Document
            </th>
            <th class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
              Status
            </th>
          </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
          <tr v-if="documentType === 'mykad' || documentType === 'passport'">
            <td class="px-6 py-4 text-center whitespace-nowrap text-sm font-medium text-gray-900">
              ID Front Image
            </td>
            <td class="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 font-bold">
                <span :class="documentStatuses.doc_front_status === 'Completed' ? 'text-green-600' : 'text-red-600'">
                  {{ documentStatuses.doc_front_status }}
                </span>
            </td>
          </tr>
          <tr v-if="documentType === 'mykad'">
            <td class="px-6 py-4 text-center whitespace-nowrap text-sm font-medium text-gray-900">
              ID Back Image
            </td>
            <td class="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 font-bold">
                <span :class="documentStatuses.doc_back_status === 'Completed' ? 'text-green-600' : 'text-red-600'">
                  {{ documentStatuses.doc_back_status }}
                </span>
            </td>
          </tr>
          <tr v-if="documentType === 'mykad' || documentType === 'passport'">
            <td class="px-6 py-4 text-center whitespace-nowrap text-sm font-medium text-gray-900">
              Selfie Photo
            </td>
            <td class="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 font-bold">
                <span :class="documentStatuses.selfie_image_status === 'Completed' ? 'text-green-600' : 'text-red-600'">
                  {{ documentStatuses.selfie_image_status }}
                </span>
            </td>
          </tr>
          </tbody>
          <!-- <tfoot class="bg-gray-50">
            <tr>
              <td class="px-6 py-6 text-sm font-medium text-gray-900 uppercase">
                Overall EKYC Status
              </td>
              <td v-if="!gettingScorecardResult">
                <div class="uppercase text-sm font-bold px-2 py-2 rounded-md text-white mx-2" :class="overallEkycStatus === 'Passed' ? 'bg-green-600' : 'bg-red-600'">
                  {{ overallEkycStatus }}
                </div>
              </td>
              <td v-else>
                <span class="loading loading-spinner loading-sm"></span>
              </td>
            </tr>
          </tfoot> -->
        </table>
      </div>

      <div class="mt-12 px-4">
        <p v-if="overallEkycStatus === 'Passed'" class="font-bold text-green-500">Congratulations! <br> e-KYC process completed successfully! <br> Please proceed to submit Okapi application.</p>
        <p v-if="overallEkycStatus === 'Failed'" class="font-bold text-orange-500">Unable to verify eKYC automatically. Please click "Proceed to Lease Application" below for manual verification</p>
      </div>

      <div class="py-10 w-full">
        <div v-if="gettingScorecardResult" class="flex items-center justify-center w-full gap-4 px-8 py-4 text-white bg-gray-500 rounded-xl cursor-not-allowed">
          <span class="loading loading-spinner loading-sm"></span>
          <span>Processing documents...</span>
        </div>
        <div v-else>
          <button class="w-full px-8 py-4 text-white bg-blue-500 rounded-xl" @click="proceedToLeaseApplication">Proceed to Lease Application</button>
          <button v-if="overallEkycStatus === 'Failed' && canRetry" class="w-full px-8 py-4 mt-4 text-white bg-blue-500 rounded-xl" @click="retryEkyc">Retry EKYC Process</button>
        </div>
        <div v-if="overallEkycStatus === 'Failed' && retryCount < 2" class="pt-3 text-red-500 text-left">
          <p class="font-bold underline pb-2">Reminder:</p>
          <p>- Ensure the images are clear.</p>
          <p>- All information must be readable and clearly visible.</p>
          <p>- All corners of the ID document must be captured.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store';
import settings from "@/composables/useSettings";

export default {
  name: 'UploadedDocumentView',
  data() {
    return {
      documentType: sessionStorage.getItem('documentType') || 'None',
      documentStatuses: {
        doc_front_status: 'Loading...',
        doc_back_status: 'Loading...',
        selfie_image_status: 'Loading...'
      },
      overallEkycStatus: '',
      gettingScorecardResult: true,
      ekycSettings: null,
    };
  },
  computed: {
    retryCount() {
      console.log('[EKYC Summary] Calculating retry count');
      return parseInt(localStorage.getItem('retryCount') || '0');
    },
    canRetry() {
      console.log('[EKYC Summary] Checking if retry is allowed');
      return settings.isEkycEnabled() && this.retryCount < 2;
    }
  },
  mounted() {
    console.log('[EKYC Summary] Component mounted');
    let journeyId = sessionStorage.getItem('journeyId');
    if (journeyId !== null) {
      localStorage.setItem('journeyId', journeyId);
    }

    const docType = sessionStorage.getItem('documentType');
    if (!journeyId) {
      console.error('[EKYC Summary] No journey ID found, redirecting to start');
      this.$router.push({ name: 'start-ekyc' });
    }else {
      console.log('[EKYC Summary] Fetching document status');
      this.getDocumentStatus(journeyId);
    }
    if (docType === 'mykad'){
      console.log('[EKYC Summary] Centralizing ID front doc');
      this.centralizeIdFrontDoc(journeyId);
    } if (docType === 'passport'){
      console.log('[EKYC Summary] Centralizing passport doc');
      this.centralizePassportDoc(journeyId);
    }
    // sessionStorage.clear();
  },
  methods:{
    async checkEkycAccess() {
      console.log('[EKYC Summary] Checking eKYC access');
      if (!settings.getSettings()) {
        await settings.fetchSettings()
      }
      return settings.isEkycEnabled() && settings.canRetryEkyc()
    },
    async getDocumentStatus(journeyId) {
      console.log('[EKYC Summary] Fetching document status');
      try {
        const response = await this.$http.get('/api/get-status', { params: { journeyId } });
        console.log('[EKYC Summary] API Response:', response.data);
        
        if (response.data.success) {
          console.log('[EKYC Summary] Status fetched successfully');
          // Map "Passed" status to "Completed"
          const mappedStatuses = Object.keys(response.data.status).reduce((acc, key) => {
            acc[key] = response.data.status[key] === 'Passed' ? 'Completed' : response.data.status[key];
            return acc;
          }, {});
          this.documentStatuses = mappedStatuses;
        }
      } catch (error) {
        console.error('[EKYC Summary] Error getting document status:', error);
        if (error.response) {
          console.error('[EKYC Summary] Response data:', error.response.data);
          console.error('[EKYC Summary] Response status:', error.response.status);
        }
      }
    },

    async centralizePassportDoc(journeyId) {
      console.log('[EKYC Summary] Centralizing passport doc');
      try {
        const checkStatuses = async () => {
          await this.getDocumentStatus(journeyId);
          const allStatusesCompleted = this.documentStatuses.doc_front_status === 'Completed' && this.documentStatuses.selfie_image_status === 'Completed';
          return allStatusesCompleted;
        };

        let allStatusesCompleted = await checkStatuses();
        if (!allStatusesCompleted) {
          this.gettingScorecardResult = false;
          this.overallEkycStatus = 'Failed';
          return;
        }

        console.log('doc front status' , this.documentStatuses.doc_front_status)
        console.log('selfie image status' , this.documentStatuses.selfie_image_status)
        console.log('all status' , allStatusesCompleted)

        const response = await this.$http.post('/api/centralize-passport-doc', { journeyId });
        console.log('[EKYC Summary] API Response:', response.data);
        // await this.getScorecardResult(journeyId)
        const scoreCardResult = await this.getScorecardResult(journeyId);
        
        if (scoreCardResult.data.status === "success"){
          console.log('[EKYC Summary] Scorecard result fetched successfully');
          switch (scoreCardResult.data.scorecardResultList[0].scorecardStatus) {
            case 'clear':
              this.overallEkycStatus = 'Passed';
              break;
            case 'cautious', 'suspicious':
              this.overallEkycStatus = 'Failed';
              break;
            default:
              this.overallEkycStatus = 'Failed';
              break;
          }
          this.gettingScorecardResult = false;
        } else {
          console.error('[EKYC Summary] Error getting scorecard result');
          this.$toast.error('Unable to get overall eKYC status');
          this.gettingScorecardResult = false;
          this.overallEkycStatus = 'Failed';
        }

      } catch (error) {
        console.error('[EKYC Summary] Error centralizing passport doc:', error);
        if (error.response) {
          console.error('[EKYC Summary] Response data:', error.response.data);
          console.error('[EKYC Summary] Response status:', error.response.status);
          if (error.response.status === 'error'){
            this.$toast.error('Inappropriate image format, please retake');
          }
        }
      }
    },

    async centralizeIdFrontDoc(journeyId) {
      console.log('[EKYC Summary] Centralizing ID front doc');
      try {
        const checkStatuses = async () => {
          await this.getDocumentStatus(journeyId); // Fetch updated statuses
          const allStatusesCompleted = Object.values(this.documentStatuses).every(
            status => status === 'Completed'
          );
          return allStatusesCompleted;
        };

        let allStatusesCompleted = await checkStatuses();
        if (!allStatusesCompleted) {
          this.gettingScorecardResult = false;
          this.overallEkycStatus = 'Failed';
          return;
        }

        const response = await this.$http.post('/api/centralize-id-front-doc', { journeyId });
        console.log('[EKYC Summary] API Response:', response.data);
        const scoreCardResult = await this.getScorecardResult(journeyId);

        if (scoreCardResult.data.status === "success"){
          console.log('[EKYC Summary] Scorecard result fetched successfully');
          switch (scoreCardResult.data.scorecardResultList[0].scorecardStatus) {
            case 'clear':
              this.overallEkycStatus = 'Passed';
              break;
            case 'cautious', 'suspicious':
              this.overallEkycStatus = 'Failed';
              break;
            default:
              this.overallEkycStatus = 'Failed';
              break;
          }
          this.gettingScorecardResult = false;
        } else {
          console.error('[EKYC Summary] Error getting scorecard result');
          this.$toast.error('Unable to get overall eKYC status');
          this.gettingScorecardResult = false;
          this.overallEkycStatus = 'Failed';
        }

      } catch (error) {
        console.error('[EKYC Summary] Error centralizing ID front doc:', error);
        if (error.response) {
          console.error('[EKYC Summary] Response data:', error.response.data);
          console.error('[EKYC Summary] Response status:', error.response.status);
          if (error.response.status === 'error'){
            this.$toast.error('Inappropriate image format, please retake');
          }
        }
      }
    },
    async getScorecardResult(journeyId) {
      console.log('[EKYC Summary] Fetching scorecard result');
      try {
        const response = await this.$http.get('/api/get-scorecard-result', { params: { journeyId } });
        console.log('[EKYC Summary] API Response:', response.data);
        return response.data;
      } catch (error) {
        console.error('[EKYC Summary] Error getting scorecard result:', error);
        if (error.response) {
          console.error('[EKYC Summary] Response data:', error.response.data);
          console.error('[EKYC Summary] Response status:', error.response.status);
        }
      }
    },
    retryEkyc() {
      console.log('[EKYC Summary] Initiating retry');
      const currentRetryCount = parseInt(localStorage.getItem('retryCount'));
      console.log('[EKYC Summary] Current retry count:', currentRetryCount);
      console.log('[EKYC Summary] Checking if retry is allowed:', settings.isEkycEnabled());

      // if (settings.isEkycEnabled() && currentRetryCount < 2) {
        console.log('[EKYC Summary] Retry allowed, proceeding');
        this.$router.push({name: 'start-ekyc', query: {...this.$route.query}});
      // }
    },
    proceedToLeaseApplication() {
      console.log('[EKYC Summary] Proceeding to lease application');
      // Mixpanel tracking
      if (process.env.VUE_APP_ENV === 'production'){
        try {
          this.$mixpanel.track('Proceed to Lease Application', {
            userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
            current_form: 'E-Kyc Status Summary',
            selected_document_type: sessionStorage.getItem('documentType'),
            journey_id: sessionStorage.getItem('journeyId'),
            ekyc_status: this.overallEkycStatus,
          });
        } catch (error) {
          console.log('Mixpanel tracking error: ', error);
        }
      }

      const journeyId = sessionStorage.getItem('journeyId');
      const key = this.$route.query.key; // Get the key from the current URL
      if (journeyId) {
        console.log('[EKYC Summary] Redirecting to quote with journey ID and key');
        this.$router.push({ name: 'quote', query: { JID : journeyId, key } });
      } else {
        console.error('[EKYC Summary] Journey ID is missing.');
      }
    }
  }
};
</script>
